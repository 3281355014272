<template>
	<div>
		<EventDescription :loading-parent="loadingEvent" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.title ? `${this.$t('events.event')} - ${this.title}` : this.$t('events.event')
		}
	},
	data() {
		return {
			title: ''
		}
	},
	components: {
		EventDescription: () => import('@/components/events/EventDescription.vue')
	},
	computed: {
		...mapGetters({
			event: 'events/event',
			loadingEvent: 'events/loading'
		})
	}
}
</script>
